import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';

import BlogCard from 'components/Card/Blog';
import { ContentWrapper, SectionTitle } from 'components/Common';
import { Wrapper, ArticleList, StyledCard } from './styles';

export interface ArticlesQuery {
  articles: {
    edges: {
      node: {
        id: string;
        fields: {
          slug: string;
        };
        excerpt: string;
        frontmatter: {
          title: string;
          cover: {
            childImageSharp: {
              fluid: FluidObject | FluidObject[];
            };
          };
        };
      };
    }[];
  };
}

const data = graphql`
  {
    articles: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "article" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt
          frontmatter {
            title
            cover {
              childImageSharp {
                fluid(quality: 50, maxWidth: 430) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

type Props = {
  id: string;
};

const ArticlesView: FC<Props> = ({ id }) => {
  const { articles } = useStaticQuery<ArticlesQuery>(data);

  return (
    <Wrapper id={id}>
      <ContentWrapper>
        <SectionTitle>Nasze artykuły</SectionTitle>
        <ArticleList>
          {!articles.edges.length
            ? 'Niestety nie ma jeszcze żadnych artykułów'
            : articles.edges.map(({ node }) => (
                <StyledCard
                  as={BlogCard}
                  key={node.id}
                  to={node.fields.slug}
                  fluid={node.frontmatter.cover.childImageSharp.fluid}
                  title={node.frontmatter.title}
                  text={node.excerpt}
                />
              ))}
        </ArticleList>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ArticlesView;
