import styled from 'styled-components';
import { SectionWrapper } from 'components/Common';

export const Wrapper = styled(SectionWrapper)`
  margin: 13.5rem auto;
`;

export const ArticleList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 930px;
`;

export const StyledCard = styled.div`
  flex: 1 0 100%;
  max-width: 428px;
  margin: 0 0 4.2rem;

  ${({ theme }) => theme.mq.mobile} {
    flex: 1 0 300px;
    margin-right: 2rem;
  }
`;
