import React, { FC } from 'react';
import { PageProps } from 'gatsby';

import Head from 'views/Head';
import Articles from 'views/Articles';

const BlogPage: FC<PageProps> = () => (
  <>
    <Head title="Blog" />
    <Articles id="articles" />
  </>
);

export default BlogPage;
